.a1 {
  background-color: black;
  padding-top: 40px;
  padding-bottom: 40px;
}

.a2 {
  color: white;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.a3 {
  color: white;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.a4 {
  border: 1px solid #303030;
  border-radius: 10px;
  margin-right: 10px;
  background-color: #18191B;
  margin-top: 20px;
  margin-bottom: 20px;
}

.a5 {
  text-align: center;
  color: white;
  padding-top: 15px;
}

.a6 {
  text-align: center;
  color: #6b6b6b;
  font-weight: 700;
  padding-bottom: 30px;
}

.a7 {
  width: 350px;
}

@media only screen and (max-width: 600px) {
  .a7 {
    width: 250px;
  }
}

.a8 {
  border-bottom: 5px solid #7eb500;
}

.a9 {
  height: 40px;
  background-color: #5C636A;
}

.a10 {
  float: right;
  color: white;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  margin-right: 10px;
margin-top: 5px;
  border-radius: 10px;
  background-color: black;
  padding: 2px;
  padding-left: 5px;
  padding-right: 5px;
}

.a11 {
  background-color: #F6F6F9;
}

.a12 {
  width: 100%;
  border-radius: 15px;
}

.center {
  margin: auto;
  width: 100%;
}

.cen {
  margin: auto;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .a13 {
    border-bottom: 2px solid #7eb500;
    margin-bottom: 20px;
  }

  .center {
    margin: auto;
    width: 100%;
  }

  .cen {
    margin: auto;
    width: 100%;
  }

}

.a14 {
  margin-top: 50px;
  font-size: 55px;
}

.a15 {
  font-size: 55px;
  font-style: italic;
  margin-top: -20px;
  margin-bottom: 60px;
}

.a16 {
  border-top: 2px solid grey;
  border-bottom: 2px solid grey;
  margin-right: 20px;
  margin-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.a17 {
  width: 50px;
}

.a18 {
  margin-left: 20px;
  margin-bottom: 50px;
}

.a19 {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.a20 {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.a21 {
  width: 100%;
}

.a22 {
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
  margin-top: 10px;
}

.a23:hover {
  transform: scale(1.1);
  transform-origin: 50% 50%;
  transition: transform .4s;
}

.a24 {
  width: 100%;
  overflow: hidden;
  transition: box-shadow .3s;
  margin-bottom: 30px;

}

.a24:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, .2);
  transition: box-shadow .3s;
}

.a25 {
  margin-right: 15px;
}

.a26 {
  background-color: #edece8;
  padding-top: 20px;
}

/* this is css for techie page */

.a27 {
  font-size: 43px;
  font-weight: 700;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
}

.a28 {
  width: 8%;
}

.a29 {
  border: 3px solid grey;
  margin: 2px;
  padding: 20px 20px;
  align-items: flex-start;
  display: flex;
  gap: 15px;
}

.a29:hover {
  border-image: linear-gradient(to right, #6CAFFF, #FF81A5, #FFC940) 30;
  border-width: 3px;
  border-style: solid;

}

.a30 {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 9px;
  padding-bottom: 9px;
  border-radius: 25px;
  font-size: 18px;
  font-weight: 500;
  background-image: linear-gradient(to right, #a6cbf8, #FF81A5, #FFC940, #FFC940);
  border: none;
  margin-top: 30px;
  margin-bottom: 30px;
}

.a31 {
  background-color: #121112;
  border-radius: 30px;
}

.a32 {
  color: white;
}

.a33 {
  width: 100%;
  border-radius: 15px;
  margin-bottom: 20px;
}

#one {
  background-color: #121112;
  border: 1px solid gray;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;

}

#one:hover {
  background-color: #343434;
  transition: 0.4s;
  border: 1px solid #7eb500;
}

#one .a34 {
  color: white;
}

#one:hover .a34 {
  background: linear-gradient(115.01deg, #6CAFFF 7.37%, #FF7BAD 39.54%, #FFC940 81.42%, #FFC940 123.91%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: 0.4s;
}

.a35 {
  margin-bottom: 20px;
}

.a36 {
  background-color: #FF7BAD;
}

/* This is help file css */

.n1 {
  background-color: #112121;
}

.n2 {
  border: 1px solid #82D89F;
  padding-top: 80px;
  padding-bottom: 80px;
  color: white;
  font-size: 20px;
  font-weight: 500;
}

.n2:hover {
  background-color: #82D89F;
  color: black;
}

.n3 {
  font-size: 60px;
  color: white;
  font-weight: 700;
  text-align: left;

}

.n4 {
  padding: 75px;
  margin-left: 50px;

}

.n5 {
  background-color: black;
  color: white;
  border: 1px solid black;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.n5:hover {
  background-color: #112121;
  color: black;
}

@media only screen and (max-width: 600px) {

  .n3 {
    font-size: 40px;
    color: white;
    font-weight: 700;
    text-align: left;

  }

  .n4 {
    padding: 35px;
    margin-left: 5px
  }

}


/* This is courses style sheet */

.b30 {

  font-size: 18px;
  color: grey;
  font-weight: 500;
}

.b18 {

  color: rgb(66, 110, 0);
  text-decoration: underline;
}

.b21 {

  transition: box-shadow 0.8s ease;
}

.b21:hover{
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  transform: translateY(-2px);
}

.b16 {
  height: 105px;
  border-bottom: 1px solid #97d404;
  margin-bottom: 20px;

}

.b12 {

  padding: 5px;
  border-radius: 10px;
  border: 1px solid #97d404;
  font-weight: 650;
}

.b14 {
  font-weight: 500;
}

.b17 {

  font-weight: 550;
}

.b15{
  margin-right: 15px;
  color: #7EB300;
}

.b13{
  border: 1px solid #97d404;
  border-radius: 5px;
  padding: 3px;
}

.c1{
  background-color: lightgray;
  width: 100%;
}

.n33{
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  z-index: 1;
}

.m1{
  background-color: black;
}

.m2{
  background-color: #303030;
  border-radius: 10px;
  padding: 10px;
  margin-top: 70px;
  margin-bottom: 30px;
  color: white;
}

.m3{
  color: #7EB300;
  text-align: center;
  margin-top: 50px;
  font-size: 50px;
}

.m4{
  font-size: 30px;
  text-align: center;
  color: white;
  margin-bottom: 40px;
}

.e1{
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #000000;
  border-radius: 10px;
}

.e2{
  text-align: center;
}

.e3{
  color: white;
  padding: 40px;
}